.upload-container {
  font-family: Arial, sans-serif;
  
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.content {
  display: flex;
  gap: 30px;
}

.left-section,
.right-section {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-section {
  border: 2px solid #007bff;
}

.right-section {
  border: 2px solid #ffc107;
}

h3 {
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
}
.textarea {
  position: relative; /* Ensure this container allows absolute positioning inside it */
  width: calc(100% - 4rem); /* Adjust width dynamically based on parent container */
  height: 100px; /* Fixed height */
  overflow-y: auto; /* Enable scrolling for overflow */
  padding: 0.8rem;
  padding-left: 3.5rem; /* Leave space for the icon */
  margin: 0.5rem 0;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(24, 201, 133, 0.804);
  outline: none;
  background-color: #ffffff;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  resize: none; /* Disable resizing */
  transition: all 0.3s ease;
}

.textarea::placeholder {
  color: #bbb;
  font-style: italic;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .textarea {
    width: 100%; /* Full width on smaller screens */
    padding-left: 1rem; /* Reduce padding to avoid excessive whitespace */
  }
}


/* Icon container */
.attach-icon {
  position: absolute; /* To position the icon relative to the textarea */
  top: 15px; /* Adjust to align properly with textarea */
  left: 6px; /* Slightly inside the textarea container */
  font-size: 25px;
  color: #db3510;
  background: linear-gradient(40deg, #6edb21, #068fffa1, #090dcfa1);
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.attach-icon:hover {
  transform: scale(1.1);
}

.file-list {
  margin-top: 10px;
  padding: 10px;
}
.submit-button {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(54, 181, 54);
  color: white;
  border: none;
  padding: 0px 10%;
  margin-left: 32%;
  cursor: pointer;
  border-radius: 20px;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.1s;
}

.submit-button:hover {
  background-color: #75b90e;
}

.submit-button:active {
  background-color: #2ad3df; /* Darker green to indicate a click */
  transform: scale(0.95); /* Slightly reduces size for a "pressing" effect */
  box-shadow: 0 2px #f4f2f2; /* Adds a slight shadow to simulate depth */
}


.process-section {
  margin-bottom: 20px;
}

.process-section h4 {
  font-size: 1.2em;
  color: #31cf90;
  margin-bottom: 10px;
}

.history-list {
  list-style-type: none;
  padding: 0;
}

.history-list li {
  background: #f9f9f9;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e4beed;
  font-size: 0.95em;
}

.history-list li:nth-child(even) {
  background: #f1f1f1;
}
.file-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.file-list {
  width: 100%;
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.file-item:last-child {
  margin-bottom: 0;
}

.file-name {
  flex-grow: 1;
  word-break: break-word; /* Handle long filenames */
}

.remove-file {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin-left: 10px;
}

.remove-all {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #c53e39;
  color: white;
  border: 5px;
  padding: 8px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.remove-all:hover {
  background-color: #f3120a;
}
/* Modal overlay */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content box */
.modal-content {
  position: relative; /* Allows positioning of child elements */
  background: white;
  padding: 20px 20px 40px;
  border-radius: 8px;
  width: 350px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
}

/* Close button (X) */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.close-button:hover {
  color: #ff0000;
}

/* Fade-in animation for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.uploading-status {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.loading-spinner {
  display: block;
  margin: 40px auto;
  width: 20px;
  height: 20px;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #0cbad1; /* Spinner color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.pagination-controls {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pagination-buttons{
  margin-top: -25px;
  margin-right: 3px;
  margin-left: 3px;
  margin-bottom: -20px;
  background-color: #41e7dc  ;
  color: white;
  border-radius: 3;
  border-color: #0ae4f0;
  font-size: 16px;
  
}
.pagination-buttons.disabled {
  cursor: not-allowed;
}

.pagination-text {

  margin-right: 3px;
  margin-left: 3px;
  margin-top: -5px;
  font-size: 16px;
  color: rgb(26, 156, 231);
}


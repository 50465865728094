/* Container for the Status Component */
.status-container {
  width: 100%;
   padding: 30px;
   font-family: "Arial, sans-serif";
   background-color: #e3f2fd; /* Light blue background for a clean design */
   border-radius: 8px;
   box-shadow: 0 4px 6px rgba(16, 118, 219, 0.93);
 }
 
 /* Heading for the Status Component */
 .status-heading {
   font-size: 24px;
   margin-bottom: 20px;
   text-align: center;
   color: #0d47a1; /* Dark blue color */
 }
 
 /* Row containing the stats */
 .status-row {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;
   padding: 20px;
   border-radius: 8px;
   background-color: #bbdefb; /* Softer blue for the row */
 }
 
 /* Individual stat box */
 .status-box {
   height: 20%;
   text-align: center;
   padding: 10px;
   border: 1px solid #64b5f6; /* Blue border */
   margin: 0 5px;
   border-radius: 8px;
   background-color: #ffffff; /* White background for stat boxes */
   color: #1976d2; /* Blue text */
   font-weight: bold;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.396);
 }
 h4{
   text-align: center;
   color: #0942ed;
 }
 
 /* Add some hover effect */
 .status-box:hover {
   background-color: #dbe9f4; /* Light blue hover effect */
   cursor: pointer;
   transform: scale(1.02);
   transition: 0.3s;
 }
 
 .documents-view {
   margin-top: 20px;
   padding: 10px;
   border: 2px solid #1ec9eb;
   border-radius: 10px;
   background-color: #f9f9f9;
 }
 
 .documents-view h4 {

   margin-bottom: 10px;
   font-size: 18px;
 }
 
 

.documents-list {
 list-style-type: none;
 padding: 0;
}

.document-item {
 margin: 6px 6px;
 padding: 10px;
 border: 1px solid #cae386;
 border-radius: 5px;
 max-width: 98.3%;
}

.document-preview {
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: normal; /* Enable multi-line wrapping */
 height: 40px; /* Fixed height for collapsed view (approx. 3 lines) */
 overflow: hidden; /* Hide overflowing text */
}
.document-preview.expanded {
 height: 160px; /* Adjust to show more content with scroll */
 overflow-y: auto; /* Enable vertical scrolling */
}

.document-item .toggle-button {
 margin-top: 5px;
 margin-right: 3px;
 margin-left: 92%;
 margin-bottom: -10px;
 border-radius: 5px;
 font-size: 14px;
 color: rgb(26, 156, 231);
 padding: 3px 6px;
 font-size: 14px;
 cursor: pointer;
 border: none;
 background-color: #0de049;
 color: white;

}

.document-item .toggle-button:hover {
 background-color: #0477f2;
}
.pagination-control {
 display: flex;
 justify-content: flex-end;
 align-items: center;
 padding: 4px;
}
.pagination-button{
 margin-top: -25px;
 margin-right: 3px;
 margin-left: 3px;
 margin-bottom: -20px;
 background-color: #41e7dc  ;
 color: white;
 border-radius: 3;
 border-color: #0ae4f0;
 font-size: 16px;
 
}
.pagination-button.disabled {
 cursor: not-allowed;
}

.pagination-texts {
 margin-right: 3px;
 margin-left: 3px;
 margin-top: -5px;
 font-size: 16px;
 color: rgb(26, 156, 231);
}

.loading-spin {
 display: block;
 margin: 20px auto;
 width: 50px;
 height: 50px;
 border: 4px solid rgba(0, 0, 0, 0.2);
 border-top: 4px solid #29d10c; /* Spinner color */
 border-radius: 50%;
 animation: spin 1s linear infinite;
}

@keyframes spin {
 0% {
   transform: rotate(0deg);
 }
 100% {
   transform: rotate(360deg);
 }
}
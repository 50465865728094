/* Navbar Container */
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #0defe0; /* Green background for better visibility */
  padding: 5px 1px;
  border-bottom: 2px solid #388e3c; /* Slightly darker shade for the border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Navbar Buttons */
.nav-button {
  padding: 30px 10px;
  width: 48%;
  border: 0.1 0.1;
  border-color: rgb(167, 193, 71);
  border-radius: 10px;
  background-color: #def2ed; /* White background for contrast */
  color: #13c64f; /* Green text to match the theme */
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-button:hover {
  background-color: #13c64f; /* Darker green on hover */
  color: #fff; /* White text on hover */
  border-color: darkorange;
}
